$breakpoints: (
  "xs": (
    max-width: 575px,
  ),
  "sm": (
    min-width: 576px,
  ),
  "md": (
    min-width: 768px,
  ),
  "lg": (
    max-width: 992px,
  ),
  "xl": (
    min-width: 1200px,
  ),
  "xxl": (
    min-width: 1400px,
  ),
);

@media screen and (max-width: 992px) {
  .menu {
    display: none !important;
  }
  .menu__mobile {
    .menu__logo-mobile {
      img {
        width: 65px;
      }
    }
    .menu__cart {
      position: relative;
      margin-right: 25px;
      .cart-counter {
        position: absolute;
        left: 21px;
        top: -5px;
        background-color: #012e1a;
        color: #fff;
        width: 20px;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        font-size: 12px;
      }
    }
    button {
      border: 0;
    }
  }
}
@media screen and (min-width: 993px) {
  .menu__mobile {
    display: none;
  }
  .main-header {
    transition: 1s;
  }
  
  .menu__logo {
    img {
      height: 120px;
      transition: 1s ease-in;
  
    }
  }
  
  .small {
    top: calc(-#{$main-header-height} + #{$small-header-height});
  transition: 1s;
    .menu__logo {
      img {
        height: 69px;
        transition: 1s;
      }
    }
  
    .menu__no {
      a {
        color: transparent !important;
      }
    }
  
    // .menu__desktop {
    //   margin-top: 0px !important;
    //   transition: 1s;
    // }
  }
  
  .hide {
    top: -$main-header-height;
  
    img {
      height: 69px;
      transition: 1s;
    }
  
    .menu__no {
      a {
        color: transparent !important;
        transition-delay: .5s !important;
      }
    }
  }
  
  .menu {
    .menu__no {
      ul {
        li {
          a {
            // color: transparent;
            transition-delay: 1s;
          }
        }
      }
    }
  }
}

// @media (min-width: 576px){

// }
@media (min-width: 1200px) {



  
  .product__heading {
    margin-left: 50%;
  }
  .product__slider {
    margin-top: -120px !important;
  }
}
@media (min-width: 1470px) {


}
 @media (max-width: 1399px) {

  .product_element{
    padding-top: 5px !important;
    .product__age{
      margin: 0 !important;
    }
  }
  .product__colors {
    margin: 17px 0 0px 0 !important;
}



.product__buy{
  margin-top: 35px !important;
  padding-top: 0px !important;
}




 }
// /* Extra large devices (large desktops, 1200px and up) */
@media (max-width: 1199px) {
  .product {
    .product_element{
      padding-top: 5px !important;

  }
  .product__ages{
    margin-top: 0px !important;
    }
    .product__buy{
      margin-top: 33px !important;
     padding-bottom: 7px !important;
    }
    .product__desc{
      max-width: 100% !important;
  }

  }
  .hero {
    &__baner {
      .hero__text {
        transform: translate(95%, 5%);
        h1{
          font-size: 38px;
       
        }
        .hero__recomend {
          width: 70%;
          margin: auto;
        }
      }
    }
  }
  .menu__left{
    padding-right: 25px !important;
  }
  .menu__right{
    padding-left: 25px !important;
  }


  .opinions{
    .opinions__box{
      max-width: 355px !important;
        .opinions__text{
            padding: 55px 40px !important;
            min-height: 240px !important;
        .opinions__mum{
            bottom: 50px !important;
        }
    }
    }
}

}
// /* Large devices (desktops, 992px and up) */
@media (max-width: 991px) {
  .content__gift-img {
    img {
      top: -36% !important;
      left: 4% !important;
    }
  }
  .category__column2 {
    flex-direction: column-reverse;
  }
  .cart__product {
    position: relative;

    .cart__x {
      display: block;
      position: absolute;
      right: 40px;
      top: 20px;
    }
    .cart__quantity,
    .cart__cost {
      margin-top: 70px;
    }
    .cart__info {
      a{
      h3{
        font-size: 22px !important;
      }
    }
    }
  }

  .hero {
    &__baner {
      .hero__text {
        transform: translate(95%, 10%);

        .hero__recomend {
          width: 100%;
          margin: auto;
          p{
          font-size: 22px;
          max-width: 290px;
          margin-top: 10px;
          }
        }
        h1 {
          font-size: 28px;
          max-width: 290px;
        }
        p {
          font-size: 14px;
        }
        img {
          width: 150px;
        }
      }
   
    }
  }

  .product {
    .product__buy {
      position: relative !important;
      margin-top: 0px;
    }
    .product__ages{
      margin-top: 20px !important;
      }
  }
  .content__choice {
    a {
      .content__name {
        //opacity: 1 !important;
        height: 90px !important;
        h3{
          font-size: 28px !important;
        }
        p{
          font-size: 16px !important;

        }
      }
    }
  }
  body{
    margin-top: $main-header-height-mobile;
  }
  .hide{
    top: -$main-header-height-mobile;
  }



  .opinions{
    .opinions__box{
      max-width: 320px !important;
        .opinions__text{
            padding: 55px 30px !important;
            min-height: 240px !important;
        .opinions__mum{
            bottom: 50px !important;
        }
    }
    }
}

.blog-head{
  display: flex;
    flex-direction: column-reverse;
}

}

// /* Medium devices (tablets, 768px and up) The navbar toggle appears at this breakpoint */
@media (max-width: 767px) {
  .content__gift-img {
    display: flex;
    justify-content: center;
    img {
      position: relative !important;
      left: 0 !important;
      transform: scale(1.3);
      top: -20% !important;
    }
  }
  .content__gift-text {
    h2,
    p {
      padding-left:0px !important;
      text-align: center !important;
    }
  }


  .hero {
    &__baner {
      .hero__h1-withe {
        position: relative;
        color: #000;
        top: 20px;
        font-size: 38px;
      }
      .hero__text {
        transform: translate(0%, 0%);
        position: relative !important;
        margin-top: 30px;

        h1 {
          font-size: 38px !important;
          max-width: 80%;
        }
        .hero__recomend {
          margin-top: 30px;
          width: 100%;
          p{
            font-size: 24px;
          }
          
        }

        img {
          width: 150px;
        }
      }
   
    .hero__baner-nature{
      display: none;
    }
    .sliderNature{
      display: block;
      .flex-control-paging{
        display: none;
      }
  }
    }
  }

  .cart__quantity,
  .cart__cost {
    margin-top: 20px !important;
  }
  .cart__info {
    position: relative;
  }
  .cart__x {
    position: relative !important;
    top: 10px !important;
    left: 20px !important;
  }
  .products__more,
  .products__add {
    font-size: 13px !important;
  }
  .products__lack {
    span {
      font-size: 16px !important;
    }
  }

  .my__baner2{
    h2{
      font-size: 30px !important;
    }
  }
  .product__head {
    h1 {
      font-size: 34px !important;

    }
  }
  .content__choice {
    a {
      .content__name {
        opacity: 1 !important;
        height: 90px !important;
        h3{
          font-size: 28px !important;
          line-height: 30px !important;
        }
        p{
          font-size: 16px !important;
          line-height: 18px !important;
        }
      }
    }
  }
  
  .opinions{
    .opinions__box{
      max-width: 100% !important;
      margin:20px 0px;
        .opinions__text{
       
            min-height: 200px !important;
        .opinions__mum{
          display: inline-block !important;
          position: relative !important;
          bottom: auto !important;
          margin-top: 10px !important;
        }
    }
    }
    .slick-arrow{
      display: none !important;
    }
}
}

// /* Small devices (landscape phones, 576px and up) */
@media (max-width: 575px) {
  .category__info {
    div {
      div {
        padding: 0 30px;
      }
    }
  }
  
  .category__boxes{

    .category__item {

      padding: 0px !important;

    .category__text{
      padding: 0 30px;
    }
  }
  }
  .product{

  
  .product__head {
    h1 {
      font-size: 32px !important;
      width: 100% !important;
      position: relative !important;
      text-align: left;
    }
    .product__price{
      position: relative !important;
      text-align: left !important;
    }
    .product__delivery{
      padding-top: 30px;
      position: relative !important;
      text-align: left !important;
    
      div{
        justify-content: start !important;
        flex-direction: row-reverse !important;
       
      }
    }
    
  }
  .product__age{
    margin: 15px 0;
    }
  }
  .footer {
    .border-end {
      border: 0 !important;
    }
    .footer__right {
      border-top: 1px solid rgba(0, 0, 0, 0.2) !important;
      justify-content: left;
      margin-top: 30px;
      padding-top: 50px;
      ul {
        width: 100%;
      }
    }
  }




 
    .hero {
      &__baner {
        .hero__text {
          transform: translate(0%, 0%);
          position: relative !important;
          margin-top: 20px;

          h1 {
            font-size: 30px !important;
            max-width: 80%;
          }
          .hero__recomend {
            p{
            margin-top: 20px;
            width: 100%;

            font-size: 20px;
            }
          }

          img {
            width: 120px;
          }
        }
       
       
      }
    }

.cart__product{
    .cart__info {
      a{
      h3 {
        font-size: 16px !important;
      }
    }
    }
    .cart__img {
      img {
        padding-left: 15px;
      }
    }
    .cart__quantity{
      padding-left: 15px !important;
    }
    .cart__x{
      left: 0px !important;
    }
    .cart__submit{
      margin-left: 0px !important;
    
    }
  }

  .content__choice {
    a {
      .content__name {
        opacity: 1 !important;
        height: 80px !important;
        h3{
          font-size: 24px !important;
        }
        p{
          font-size: 14px !important;

        }
      }
    }
  }


  .flex-control-nav {
  
    margin-top: 10px !important;
}

} 

@media (max-width: 375px) {
  .contact__call {
    a {
      font-size: 26px !important;
    }
    a:hover {
      font-size: 28px !important;
    }
  }

  .cart__product{
    .cart__info {
      a{  
      h3 {
        font-size: 14px !important;
      }
      
    }
    span{
      font-size: 12px !important;
    line-height: 0px !important;
    }
    }
   
  
}


}
