footer{
    font-size: 16px;
    line-height: 28.88px;
    font-family: $font-family;
    font-weight: 300;
   
    ul{
        list-style:none;
        padding-left: 0 !important;
        a{
            color: $color-primary;
            text-decoration: none;
            font-family: 'Lato';
            font-style: normal;
            font-weight: 300;
            font-size: 16px;
            line-height: 19px;
            transition: 0.3s;
        }
        a:hover{
            color: #10653E;
        }
    }
    img{
        padding:5px;
    }
    .border-top{
        border-top: 1px solid rgba(0, 0, 0, 0.2) !important;
    }
    .border-bottom{
        border-bottom: 1px solid rgba(0, 0, 0, 0.2) !important;
    }
    .border-end{
        border-right: 1px solid rgba(0, 0, 0, 0.2) !important;
    }
}




