.menu {
  position: relative;

  .menu__no {
    ul {
      list-style-type: none;
      position: absolute;
      right: 0;
      top: 30px;
      text-align: end;

      a {
        color: $color-primary;
        @extend %text;
        text-decoration: none;
        transition: 0.3s;
      }

      a:hover {
        color: #10653E;
      }
    }
  }

  .menu__nav {
    margin-top: 30px;

    li {
      a {
        color: $color-primary;
        text-transform: uppercase;
        font-size: 16px;
        line-height: 19.2px;
        font-family: $font-family;
        font-weight: 300;
        padding: 0 0 20px 0;
        transition: 0.3s;
      }

      a:hover {
        font-weight: 500;

      }

      a.active {
        font-weight: 700;

      }

      .dropdown-menu {
        text-align: center;
        transform: translate3d(-15px, 39px, 0px) !important;
        background-color: #ECECE2;
        border: 2px solid #012e1a;
        transition: 0.7s;

        li {
          a {
            padding: 8px 0 8px 0;
            transition: 0.5s;
          }

          a:hover {
            background-color: #012e1a;
            color: #fff;
            transition: 0.5s;
          }
        }
      }
    }
  }

  .menu__logo {
    img {
      padding-bottom: 3px;
    }
  }

  .menu__left {
    padding-right: 55px;
  }

  .menu__right {
    padding-left: 55px;
  }
}

.navbar-toggler:focus {
  box-shadow: 0 0 0 0 !important;
}

.home {
  .collapse1 {
    position: absolute;
    width: 100% !important;
    max-width: 100% !important;
  }
}

.collapse1 {
  display: none;
  position: absolute;
  z-index: 99;
  width: 100%;
  max-width: 100% !important;
}

.active-menu {
  display: block;
}

.hamburger {
  margin: 0;
  padding: 0;
  border: 0;
  background-color: transparent;
  cursor: pointer;

  &:focus {
    &>.hamburger__container {
      box-shadow: 0 0 2px 2px #51a7e8;
    }
  }

  &,
  &__container {
    &:focus {
      outline: none;
    }
  }

  &__container {
    display: flex;
    align-items: center;
    position: relative;
    width: 35px;
    height: 30px;
  }

  &__bars {
    position: absolute;
    width: 35px;
    height: 2px;
    background-color: #000;
    transition: transform 220ms ease-in-out;

    &::before,
    &::after {
      display: block;
      position: absolute;
      width: 35px;
      height: 2px;
      background-color: #000;
      content: "";
    }

    &::before {
      top: -12px;
      transition: top 100ms 250ms ease-in, transform 220ms ease-in-out;
    }

    &::after {
      bottom: -12px;
      transition: bottom 100ms 250ms ease-in, transform 220ms ease-in-out;
    }
  }

  &--active {
    .hamburger__bars {
      transform: rotate(225deg);
      transition: transform 220ms 120ms ease-in-out;

      &::before {
        top: 0;
        transition: top 100ms ease-out;
      }

      &::after {
        bottom: 0;
        transform: rotate(-90deg);
        transition: bottom 100ms ease-out, transform 220ms 120ms ease-in-out;
      }
    }
  }
}

header {
  background-color: white;
}
.collapse1{
  background-color: white;
}

.main-header {
  position: fixed;
  top: 0px;
  width: 100%;
  z-index: 1000;
  transition: .5s;
  &.not-hide{
    top: 0px !important;
  }
}
.small{
  font-size: var(--bs-body-font-size);
}