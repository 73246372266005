// Utf-8
@charset "utf-8";
//@import "bootstrap/bootstrap"; // --- Vendors Bootstrap 4

$color-primary: #000000;
$color-secondary: #012E1A;
$font-family: 'Lato', sans-serif;
$font-heading: 'Oranienbaum', sans-serif;
$font-default: 16px;
$main-header-height: 168px;
$main-header-height-mobile: 82px;
$small-header-height: 119px;
//@import "responsive"; // --- Vendors Bootstrap 4




*, html, body, div, p{
    border: 0;
    padding: 0;
    box-sizing: border-box;
    margin: 0;
}
.home{
    background-color: #FFF6E4 !important;
}
.about{
    background-color: #ECECE2 !important;
}
.cart{
    background: #FFFFF4 !important;
    .main-header{
    background: #FFFFF4 !important;
}

}

@import "mixin.scss";
@import "placeholders.scss";
@import "header.scss";
@import "main.scss";
@import "return.scss";
@import "blog.scss";
@import "footer.scss";
@import "responsive.scss";




